/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import Button from "../../common/Button";
import ContactUs from "../../common/ContactUs";
import Customers from "../../common/Customers";
import Footer from "../../common/Footer";
import Intro from "../../common/Intro";
import Page from "../../common/Page";
import Meta from "../../common/Meta";
import Section from "../../common/Section";
import ProductsCallout from "../../products/ProductsCallout";
import ProductsHero from "../../products/ProductsHero";
import KuromojiSection from "../../products/ProductsKuromoji";
import {H1} from "../../common/typography/Headings";

const ProductsEn = () => (
    <Page lang="en" title="Products" path="/en/products/">
        <Meta name="description">
            Natural Language Processing Tools for East Asian and Western languages.
        </Meta>
        <ProductsHero lang="en" path="/en/products/">
            <H1>Products</H1>
            <p>Natural Language Processing Tools for East Asian and Western languages</p>
        </ProductsHero>
        <Intro>
            <p>
                Our suite of open source and proprietary natural language processing products are
                used to power the businesses of leading companies across the globe. Our proprietary
                products all come with generous licenses that allow unlimited use and modification.
                We are always happy to demonstrate our products and offer trial license keys.
            </p>
        </Intro>
        <KuromojiSection title="Kuromoji">
            <p>
                Kuromoji is our open source, Japanese morphological analyzer written in Java. Core
                features include word segmentation, part-of-speech tagging, lemmatization, readings
                for kanji, and support for multiple dictionary backends. Kuromoji powers Japanese
                language support in Apache Lucene and Apache Solr and is also used in Elasticsearch.
                For a demo and further information.
            </p>
            <p>
                In addition to our Apache License v2 Java Kuromoji, we offer a C version with a
                proprietary license and commercial support.
            </p>
            <p>
                <Button to="/en/kuromoji/" readMore>
                    more
                </Button>
            </p>
        </KuromojiSection>
        <Section title="Akahai">
            <p>
                Akahai is our query suggestion engine for Japanese, Chinese, Korean, and Western
                Languages. Akahai gives instant, high quality suggestions during search or other
                text input and can match input across any combination of hiragana, katakana, kanji
                and romaji. It also offers multi-term matching, match highlighting, and
                spell-checking, all with extremely low latency.
            </p>
            <p>
                Akahai is simple to integrate with search engines, databases, and web applications
                and serves millions of users daily.
            </p>
        </Section>
        <Section title="Keywords" grey>
            <p>
                We offer a powerful keyword extractor for Japanese. Keyword and concept extraction
                can be used for a variety of applications, such as: searching by example, content
                personalization, recommendation systems, and identifying major themes across large
                data sets.
            </p>
        </Section>
        <Section title="Entities">
            <p>
                Our named entity extractor can recognize named entities, such as person names,
                companies, locations, buildings, and religions. It currently supports texts in
                Japanese, English and Scandinavian languages. By using language context, our
                technology can tag the same word with different ypes based on usage, and is even
                able to accurately tag unknown terms. Our machine learning based models can be
                supplemented with dictionaries of custom entities specific to your domain. We also
                offer a user friendly annotation tool for building custom data to include in your
                model.
            </p>
        </Section>

        <Section title="Panda" grey>
            <p>
                Panda is our morphological analyzer for simplified Chinese. It provides high
                accuracy word segmentation, part-of-speech tagging and pinyin for Chinese text.
                Information about corresponding traditional characters is also available for each
                token.
            </p>
        </Section>

        <ProductsCallout>
            <p>
                All of our products are available in Java. Our products are also packaged with REST
                servers to allow for easy integration with any platform or language.
            </p>
        </ProductsCallout>

        <Customers title="Our customers" />

        {/*<Testimonials title="Testimonials">*/}
        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Ut tincidunt metus eget velit convallis, ut placerat sem aliquet.*/}
        {/*Aliquam hendrerit.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Ut tincidunt metus eget velit convallis, ut placerat sem aliquet.*/}
        {/*Aliquam hendrerit.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Phasellus in turpis feugiat neque elementum dictum vitae vel sem.*/}
        {/*In hac habitasse platea dictumst. Donec vulputate.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*In et dui quis elit malesuada ultrices.*/}
        {/*Etiam vitae urna at dolor mollis maximus.*/}
        {/*Quisque risus mauris, elementum id euismod a, fermentum.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme Inc, Pieter-Jan">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Suspendisse eget augue magna.*/}
        {/*Aenean urna magna, congue id nisi at, euismod blandit est.*/}
        {/*Morbi sit amet hendrerit massa, sed fringilla odio.*/}
        {/*Sed vel ornare purus.*/}
        {/*In lobortis, metus sit amet faucibus consequat,*/}
        {/*urna turpis facilisis nunc, sit amet finibus purus mi.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}
        {/*</Testimonials>*/}

        <ContactUs title="Let’s get to work">
            <p>Contact us about your project today for a free consultation.</p>
        </ContactUs>
        <Footer lang="en" />
    </Page>
);

export default memo(ProductsEn);
